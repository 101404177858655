:root {
    --col-text: #CCCCCC;
    --col-background: #333333;
    --col-background-light: #2A2A2A;
    --col-accent: #4d898a;
    --col-accent-light: #4CC9D0;
    --col-link: #00FFFF;
    --col-button: #2F4F4F;

    --font-mono: monospace;
    --font-text: Poppins, Helvetica, sans-serif;
    --font-size-std: 20px;
    --font-size-md-screen: 18px;
    --font-size-small-screen: 16px;
}

body {
    background-color: var(--col-background);
}

.root {
    display: flex;
    flex-direction: column;
    background-color: var(--col-background);
    color: var(--col-text);
    font-size: var(--font-size-small-screen);
}

.root-content {
    width: 100%;
}

a {
    color: var(--col-link);
}

.hero-header {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: var(--font-text);
}

.hero-header-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin-left: 15px;
    margin-right: 15px;
}

.hero-header-content h1 {
    font-size: 50px;
    font-weight: 700;

    background: linear-gradient(to bottom right, var(--col-text), var(--col-accent));
    background-clip: text;
    -webkit-text-fill-color: transparent;

    font-family: var(--font-text);

}

.hero-header-content h2 {
    font-size: 25px;
    color: var(--col-accent);
    font-family: var(--font-mono);

}

.hero-header-social-icons {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

h1 {
    color: var(--col-text);
}

.clickable-icon:hover {
    background-color: var(--col-accent);
}

.clickable-icon {
    background-color: var(--col-text);
    height: 25px;
    width: 25px;
}

@media screen and (min-width: 600px) {
    .clickable-icon {
        background-color: var(--col-text);
        height: 50px;
        width: 50px;
    }
}

.linkedin-icon {
    mask-image: url("/public/static/img/linkedin-logo.svg")
}

.github-icon {
    mask-image: url("/public/static/img/github-icon.svg")
}


@media screen and (min-width: 600px) {

    .root {
        font-size: var(--font-size-md-screen);
    }

    .hero-header-content h1 {
        font-size: 65px
    }

    .hero-header-content h2 {
        font-size: 30px
    }

}

@media screen and (min-width: 1000px) {

    .root {
        font-size: var(--font-size-std);
    }

    .hero-header-content h1 {
        font-size: 90px
    }

    .hero-header-content h2 {
        font-size: 50px
    }

}

.page-section h1 {
    font-family: var(--font-mono)
}

.page-section h2 {
    font-family: var(--font-mono)
}

.page-section {
    width: 100%;
    box-sizing: border-box;
    max-width: 800px;
    margin-bottom: 5em;
    text-align: justify;
    padding-left: 15px;
    padding-right: 15px;
}

.page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
}

.resume {
    display: flex;
    flex-direction: column;
}

.resume h3 {
    margin: 0;
}

.resume .role {
    position: relative;
    padding-left: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.resume .role .dot {
    position: absolute;
    top: 10px;
    left: 0;
    width: 12px;
    height: 12px;
    border-color: var(--col-accent-light);
    border-style: solid;
    border-width: 3px;
    border-radius: 50%;
}

.resume .role::after {
    content: '';
    position: absolute;
    top: 25px;
    left: 8px;
    width: 2px;

}

.resume .role .content {
    padding-top: 5px;
}

/* Add a vertical line between dots */
.resume .role:not(:last-child)::after {
    height: calc(100% - 14px);
    background-color: var(--col-accent-light);
}

/* Add a vertical line between dots */
.resume .role:last-child::after {
    height: 60%;
    background: linear-gradient(to bottom, var(--col-accent-light), transparent);
}


.resume .role .skill-list {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
}

.resume .role .content .skill-list .skill-pill {
    border-radius: 5px;
    background-color: var(--col-accent);
    color: white;
    white-space: nowrap;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: var(--font-size-std);
    font-size: var(--font-size-small-screen);
    user-select: none;
}

.resume .role .content .skill-list .skill-pill:hover {
    background-color: var(--col-accent-light);
}

.footer {
    display: flex;
    justify-content: center;
    padding: 5em;
}